@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;300;400;500;700;900&display=swap");
* {
  font-family: "Noto Sans", sans-serif;
}
*::-webkit-scrollbar {
  width: 0.3em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(202, 202, 202);
}

.MuiFormControl-root {
  width: 100%;
}
