.root {
  width: 100%;
  height: 100vh;
  display: flex;
  background: "#FFFFFF";
}
.rootLeft-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rootLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Noto Sans JP", sans-serif;
}
.rootRight {
  flex: 1;
  background: url("../../assets/login/AdobeStock_376169714.png");
  background-size: cover;
  background-position: left;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.intro h2 {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.intro p {
  font-size: 1em;
  color: #000000;
}
.helpline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 1em 0;
}
.helpline-signup,
.helpline-support {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  gap: 5px;
}

.helpline-signup a,
.helpline-support a {
  color: #0061cf;
}

@media (max-width: 1000px) {
  .rootRight {
    display: none;
  }
}

@media (max-width: 452px) {
  .input-box,
  button {
    width: 300px;
  }
}

.otpBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0em 0 1em 0;
}
.otpBox p {
  margin: 0 0 1em 0;
  font-weight: bold;
}

.otpInput {
  width: 35px !important;
  height: 40px;
}
.otpContainer > * + * {
  margin-left: 1em !important;
}
.otpSaperator {
  margin: 0 0 0 1em;
}
.otpFocus {
  outline: 1px solid rgb(49, 49, 49) !important;
}
.otpError {
  border: 1px solid red !important;
  outline: none !important;
}
.otpErrorMsg {
  margin: 0.3em 0;
  color: red;
  font-size: 0.8em;
}

.numberErrorMsg {
  margin: 0.3em 0.3em;
  color: red;
  font-size: 0.8em;
}
